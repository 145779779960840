import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function Home() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const router = useRouter();

  /**
   *  Fonction qui permet de récupérer les données de l'utilisateur quand il se connecte et de les comparer avec la BDD
   * Elle permet aussi d'attribuer des datas à la méthode signIn de next-auth 
   * @param {*} event 
   */

  const handleLogin = (event) => {
    event.preventDefault();
    event.stopPropagation();

    signIn('credentials', {
      user,
      password,
      callbackUrl: `${window.location.origin}/admin/accueil`,
      redirect: false,
    }).then((res) => {
      if (res.error !== null) {
        if (res.status === 401) {
          setLoginError('Email ou mot de passe incorrect, Merci de réessayer.');
        } else {
          setLoginError(res.error);
        }
      } else {
        router.push(res.url);
      }
    });
  };

  return (
    <div className="h-screen bg-blue-school">
      <div className="flex justify-center pt-20">
        <Image
          src={'/src/images/logo-ecole.png'}
          width={300}
          height={200}
          alt="logo école"
        />
      </div>
      <form onSubmit={handleLogin} className="w-6/12 pt-20 ml-auto mr-auto">
        {loginError}
        <label className='text-white'>
          Email:{' '}
          <input
            className="
            border-form-stroke
            text-body-color placeholder-body-color
            focus:border-primary
            active:border-primary
            w-full
            text-black
            rounded-lg
            border-[1.5px]
            py-3
            px-5
            font-medium
            outline-none
            transition
            "
            type="text"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
        </label >
        <label className='text-white'>
          Password:{' '}
          <input
            className="
            border-form-stroke
            text-body-color placeholder-body-color
            focus:border-primary
            active:border-primary
            w-full
            rounded-lg
            border-[1.5px]
            py-3
            px-5
            text-black
            font-medium
            outline-none
            transition
            disabled:cursor-default disabled:bg-[#F5F7FD]
            "
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
          <button
            className="h-12 mt-10 mb-20 ml-auto mr-auto bg-white rounded shadow-xl w-60 text-blue-school"
            type="submit"
          >
            Se connecter
          </button>
      </form>
    </div>
  );
}
